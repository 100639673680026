import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './ascension.scss';

const EsGuidesAscensionPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ascension-guide'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Ascension guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/category_ascension.png"
            alt="Ascension guide"
          />
        </div>
        <div className="page-details">
          <h1>Ascension guide</h1>
          <h2>A guide for the ascension system in Eversoul.</h2>
          <p>
            Last updated: <strong>09/06/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Ascension" />
        <StaticImage
          src="../../../images/eversoul/generic/guide_ascension_0.webp"
          alt="Guide"
        />
        <p>
          Ascension in Eversoul is the main system of progression that both
          makes your characters (Souls) stronger and increases the maximum level
          they can achieve.
        </p>
        <p>
          To enter the Ascension screen, head toward The Ark first - you can do
          it from the main lobby - and then press on the Ascend Soul tile. Once
          you do that, you will see this screen:
        </p>
        <StaticImage
          src="../../../images/eversoul/generic/guide_ascension_1.webp"
          alt="Guide"
        />
        <p>
          Before we actually do anything there, you need to know some important
          information about the character types available in Eversoul. There are
          three main ones:
        </p>
        <ul>
          <li>
            <strong>Common characters</strong> (white background - initial max
            level is 40). Honestly, they don't have any uses and we suggest to
            enable the option to automatically discard them after pulling them
            (you can do it in The Ark too). For the Dust obtained from
            discarding them you can purchase Rare/Epic Shards in the shop,
          </li>
          <li>
            <strong>Rare characters</strong> (blue background - initial max
            level is 60). You can use those in the early game, but their main
            role in Eversoul is to be Fodder for Epic characters,
          </li>
          <li>
            <strong>Epic characters</strong> (purple background - initial max
            level is 100). Those are the unique characters in Eversoul that will
            form the core of your team.
          </li>
        </ul>
        <p>
          Also, each of the 3 character types has a limit on how far they can be
          ascended.
        </p>
        <ul>
          <li>
            <strong>Common</strong> characters can't be ascended at all past
            their initial rarity,
          </li>
          <li>
            <strong>Rare</strong> characters can be ascended to Legendary+
            rarity.
          </li>
          <li>
            <strong>Epic</strong> characters can be ascended all the way to
            Transcended rarity.
          </li>
        </ul>
        <p>
          And (I promise, this is the last information dump) there are two
          important terms you should know:
        </p>
        <ul>
          <li>
            <strong>Combining</strong> - when you fuse 2 same Epic characters
            into one, you combine them into an Epic+ character. So the moment
            you get 2 same characters from Epic rarity, you can combine them
            without worrying about messing something up.
          </li>
          <ul>
            <li>
              Combining also works for Rare characters, but there you need 3
              copies of the same character - similar to the Epic combination,
              this is also a safe step, so do it every time you get 3 same Rare
              characters.
            </li>
          </ul>
          <li>
            <strong>Ascending</strong> - this happen when you fuse different
            characters with each other and here a lot of things can go wrong.
          </li>
        </ul>
        <p>
          In short: combining ={' '}
          <span className="green">
            <strong>safe</strong>
          </span>
          . Ascending ={' '}
          <span className="red">
            <strong>not safe</strong>
          </span>
        </p>
        <p>
          And this guide will help you navigate the Ascension, so you don't make
          any mistakes.
        </p>
        <SectionHeader title="Ascension tiers" />
        <p>
          Now that we have the basic information out of the way, let's focus on
          the Ascension process itself. Since common characters can't be
          ascended, the Ascension pyramid starts from a rare character.
        </p>
        <h5>Rare &gt; Rare+</h5>
        <p>
          This is a simple combination of 3 same Rare tier characters into a
          Rare+ one. You can safely use the auto-combination for this step.
        </p>
        <div className="custom-ascension">
          <div className="ascension-box rare">
            <StaticImage
              src="../../../images/eversoul/generic/example_rare.webp"
              alt="Rare"
            />
            <p>Rare</p>
          </div>
          <div className="plus-box">+</div>
          <div className="ascension-box rare">
            <StaticImage
              src="../../../images/eversoul/generic/example_rare.webp"
              alt="Rare"
            />
            <p>Rare</p>
          </div>
          <div className="plus-box">+</div>
          <div className="ascension-box rare">
            <StaticImage
              src="../../../images/eversoul/generic/example_rare.webp"
              alt="Rare"
            />
            <p>Rare</p>
          </div>
          <div className="sum-box">=</div>
          <div className="ascension-box rare-plus">
            <StaticImage
              src="../../../images/eversoul/generic/example_rare.webp"
              alt="Rare+"
            />
            <p>Rare+</p>
          </div>
        </div>
        <h5>Rare+ &gt; Epic</h5>
        <p>
          This is an ascension of 3 Rare+ tier characters into a Epic one. You
          can safely use the auto-combination for this step as the AI in
          Eversoul is pretty smart and when there are multiple choices to
          combine Rare+ characters it picks the one you already have as Epic as
          the main one (so you can then instantly combine those two into Epic+).
          Still, just to avoid any mistakes in this step, verify the
          combinations picked by the Auto feature.
        </p>
        <div className="custom-ascension">
          <div className="ascension-box rare-plus">
            <StaticImage
              src="../../../images/eversoul/generic/example_rare.webp"
              alt="Rare"
            />
            <p>Rare+</p>
          </div>
          <div className="plus-box">+</div>
          <div className="ascension-box rare-plus">
            <StaticImage
              src="../../../images/eversoul/generic/example_mystery_rare.png"
              alt="Rare"
            />
            <p>Rare+</p>
          </div>
          <div className="plus-box">+</div>
          <div className="ascension-box rare-plus">
            <StaticImage
              src="../../../images/eversoul/generic/example_mystery_rare.png"
              alt="Rare"
            />
            <p>Rare+</p>
          </div>
          <div className="sum-box">=</div>
          <div className="ascension-box epic">
            <StaticImage
              src="../../../images/eversoul/generic/example_rare.webp"
              alt="Rare+"
            />
            <p>Epic</p>
          </div>
        </div>
        <h5>Epic &gt; Epic+</h5>
        <p>
          This is a simple combination of 2 same Epic tier characters into a
          Epic+ one. You can safely use the auto-combination for this step.
        </p>
        <div className="custom-ascension">
          <div className="ascension-box epic">
            <StaticImage
              src="../../../images/eversoul/generic/example_epic.png"
              alt="Epic"
            />
            <p>Epic</p>
          </div>
          <div className="plus-box">+</div>
          <div className="ascension-box epic">
            <StaticImage
              src="../../../images/eversoul/generic/example_epic.png"
              alt="Epic"
            />
            <p>Epic</p>
          </div>
          <div className="sum-box">=</div>
          <div className="ascension-box epic-plus">
            <StaticImage
              src="../../../images/eversoul/generic/example_epic.png"
              alt="Epic+"
            />
            <p>Epic+</p>
          </div>
        </div>
        <h5>Epic+ &gt; Legendary</h5>
        <p>
          The first tricky step in the Ascension pyramid as you can massively
          hinder your progress. There are two mistakes you can make here:
        </p>
        <ul>
          <li>
            If you use a base Epic character as Fodder here.{' '}
            <strong>You should never do that. Never.</strong> It might be
            tempting to sacrifice an Epic+ you think is useless, but one day you
            will need it (either for Faction content or the character will get
            buffed) and you will regret Foddering it.{' '}
            <strong>
              So only use base Rare characters here that you ascended to Epic+
            </strong>
            ,
          </li>
          <li>
            <strong>If you Ascend someone who you only have 2 copies of</strong>
            . The step after this will require you to have an Epic+ same
            character, so if you don't have it, you will be stuck there. Maybe
            for a long time if rng hates you.
          </li>
        </ul>
        <p>
          This is why in the spots where you see the mystery character, you
          should only place Rare character that you Ascended to Epic+. And also
          only Ascend someone to Legendary for whom you have 4 copies of (so
          enough to make 2x Epic+ of that character).
        </p>
        <p>
          And last thing -{' '}
          <strong>
            the mystery epic characters you use as Fodder must belong to the
            same faction
          </strong>{' '}
          that the Epic+ you want to raise belongs.
        </p>
        <div className="custom-ascension">
          <div className="ascension-box epic-plus">
            <StaticImage
              src="../../../images/eversoul/generic/example_epic.png"
              alt="Epic+"
            />
            <p>Epic+</p>
          </div>
          <div className="plus-box">+</div>
          <div className="ascension-box epic-plus">
            <StaticImage
              src="../../../images/eversoul/generic/example_mystery_epic.png"
              alt="Epic+"
            />
            <p>Epic+</p>
          </div>
          <div className="plus-box">+</div>
          <div className="ascension-box epic-plus">
            <StaticImage
              src="../../../images/eversoul/generic/example_mystery_epic.png"
              alt="Epic+"
            />
            <p>Epic+</p>
          </div>
          <div className="sum-box">=</div>
          <div className="ascension-box legend">
            <StaticImage
              src="../../../images/eversoul/generic/example_epic.png"
              alt="Legendary"
            />
            <p>Legend</p>
          </div>
        </div>
        <h5>Legendary &gt; Legendary+</h5>
        <p>
          And we're back to the easy stuff. Here you simply need an Epic+ copy
          of the character to Ascend it from Legendary to Legendary+.
        </p>
        <p>
          This is the end of the road for base Rare characters, as they can't be
          raised past Legendary+ tier.
        </p>
        <div className="custom-ascension">
          <div className="ascension-box legend">
            <StaticImage
              src="../../../images/eversoul/generic/example_epic.png"
              alt="Legend"
            />
            <p>Legend</p>
          </div>
          <div className="plus-box">+</div>
          <div className="ascension-box epic-plus">
            <StaticImage
              src="../../../images/eversoul/generic/example_epic.png"
              alt="Epic+"
            />
            <p>Epic+</p>
          </div>
          <div className="sum-box">=</div>
          <div className="ascension-box legend-plus">
            <StaticImage
              src="../../../images/eversoul/generic/example_epic.png"
              alt="Legend+"
            />
            <p>Legend+</p>
          </div>
        </div>
        <h5>Legendary+ &gt; Eternal</h5>
        <p>
          To reach Eternal tier, you need to raise a Rare character (from the
          same faction) all the way to this step. This will require a lot of
          Rare characters and it's a grind. Also, never use a base Epic
          character that you raised to Legendary+ to fodder here. Ever.
        </p>
        <div className="custom-ascension">
          <div className="ascension-box legend-plus">
            <StaticImage
              src="../../../images/eversoul/generic/example_epic.png"
              alt="Epic"
            />
            <p>Legend+</p>
          </div>
          <div className="plus-box">+</div>
          <div className="ascension-box legend-plus">
            <StaticImage
              src="../../../images/eversoul/generic/example_mystery_legend.png"
              alt="Epic"
            />
            <p>Legend+</p>
          </div>
          <div className="sum-box">=</div>
          <div className="ascension-box eternal">
            <StaticImage
              src="../../../images/eversoul/generic/example_epic.png"
              alt="Epic+"
            />
            <p>Eternal</p>
          </div>
        </div>
        <h5>Eternal &gt; Eternal+</h5>
        <p>
          Basically a repeat of previous step - you need another Legendary+
          character from the same faction to Fodder here.
        </p>
        <div className="custom-ascension">
          <div className="ascension-box eternal">
            <StaticImage
              src="../../../images/eversoul/generic/example_epic.png"
              alt="Epic"
            />
            <p>Eternal</p>
          </div>
          <div className="plus-box">+</div>
          <div className="ascension-box legend-plus">
            <StaticImage
              src="../../../images/eversoul/generic/example_mystery_legend.png"
              alt="Epic"
            />
            <p>Legend+</p>
          </div>
          <div className="sum-box">=</div>
          <div className="ascension-box eternal-plus">
            <StaticImage
              src="../../../images/eversoul/generic/example_epic.png"
              alt="Epic+"
            />
            <p>Eternal+</p>
          </div>
        </div>
        <h5>Eternal+ &gt; Origin</h5>
        <p>
          To reach Origin, you need to combine the Eternal+ character with 2
          Epic+ copies of her.
        </p>
        <p>
          Overall, to raise an base Epic character to Origin you will need in
          total 8 copies of her.
        </p>
        <div className="custom-ascension">
          <div className="ascension-box eternal-plus">
            <StaticImage
              src="../../../images/eversoul/generic/example_epic.png"
              alt="Epic"
            />
            <p>Eternal+</p>
          </div>
          <div className="plus-box">+</div>
          <div className="ascension-box epic-plus">
            <StaticImage
              src="../../../images/eversoul/generic/example_epic.png"
              alt="Epic"
            />
            <p>Epic+</p>
          </div>
          <div className="plus-box">+</div>
          <div className="ascension-box epic-plus">
            <StaticImage
              src="../../../images/eversoul/generic/example_epic.png"
              alt="Epic"
            />
            <p>Epic+</p>
          </div>
          <div className="sum-box">=</div>
          <div className="ascension-box origin">
            <StaticImage
              src="../../../images/eversoul/generic/example_epic.png"
              alt="Epic+"
            />
            <p>Origin</p>
          </div>
        </div>
        <h5>Transcension</h5>
        <p>
          This step isn't required as you won't increase the max level cap
          anymore. Still, you can combine the Origin character with Epic+ copies
          of her to make her even stronger, but the amount of stats you get from
          Transcending is was smaller compared to previous stages.
        </p>
        <p>You can Transcend a character up to +5.</p>
        <div className="custom-ascension">
          <div className="ascension-box origin">
            <StaticImage
              src="../../../images/eversoul/generic/example_epic.png"
              alt="Epic+"
            />
            <p>Origin</p>
          </div>
          <div className="plus-box">+</div>
          <div className="ascension-box epic-plus">
            <StaticImage
              src="../../../images/eversoul/generic/example_epic.png"
              alt="Epic"
            />
            <p>Epic+</p>
          </div>
          <div className="sum-box">=</div>
          <div className="ascension-box origin-plus">
            <StaticImage
              src="../../../images/eversoul/generic/example_epic.png"
              alt="Epic+"
            />
            <p>Origin+1</p>
          </div>
        </div>
        <SectionHeader title="Angel, Demon and Chaos" />
        <p>
          Angel, Demon and Chaos characters are ascended in different manner as
          there are no Fodder units present for these factions, thus
        </p>
        <ul>
          <li>Epic+ - Epic and Epic (Same Character) - 2 Copies Total</li>
          <li>Legendary - Epic+ and Epic+ (Same Character) - 4 Copies Total</li>
          <li>
            Legendary+ - Legendary and Epic+ (Same Character) - 6 Copies Total
          </li>
          <li>
            Eternal - Legendary+ and Epic+ (Same Character) - 8 Copies Total
          </li>
          <li>
            Eternal+ - Eternal and Epic+ (Same Character) - 10 Copies Total
          </li>
          <li>
            Origin - Eternal+, Epic+ and Epic+ (Same Character) - 14 Copies Tota
          </li>
        </ul>
        <p>
          Thus for ascension of Angel, Demon and Chaos character to origin, you
          need 14 copies of the same character compared to 8 copies for other
          factions.
        </p>
        <SectionHeader title="Level cap" />
        <p>The max level a character can reach depends on its tier:</p>
        <ul>
          <li>Common - level 40</li>
          <li>Rare - level 60</li>
          <li>Rare+ - level 80</li>
          <li>Epic - level 100</li>
          <li>Epic+ - level 120</li>
          <li>Legendary - level 140</li>
          <li>Legendary+ - level 160</li>
          <li>Eternal - level 180</li>
          <li>Eternal+ - level 200</li>
          <li>Origin - level 240</li>
        </ul>
        <p>
          Each character you ascend to Origin will add +5 levels to the max
          level you can reach.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesAscensionPage;

export const Head: React.FC = () => (
  <Seo
    title="Ascension guide | Eversoul | Prydwen Institute"
    description="A guide for the ascension system in Eversoul."
    game="eversoul"
  />
);
